import React from 'react';
import PropTypes from 'prop-types';
import DefaultPicture, {
  propTypes as defaultPicturePropTypes,
} from '../../../01_atoms/DefaultPicture';
import {
  generateClassNameByBehaviorSettings,
  behaviorSettingsProps,
} from '../../../../utils/behaviorSettings';
import Link, { propTypes as linkPropTypes } from '../../../01_atoms/Link';
import Button from '../../../01_atoms/Button';
import BalloonsIcon from '../../../../public/static/icons/balloons.svg';
import SubBalloonsIcon from '../../../../public/static/icons/sub-balloons.svg';
import FlowerIcon from '../../../../public/static/icons/flower.svg';
import SubFlowersIcon from '../../../../public/static/icons/sub-flowers.svg';
import HeartsIcon from '../../../../public/static/icons/hearts.svg';
import SubHeartsIcon from '../../../../public/static/icons/sub-hearts.svg';

import styles from './index.module.scss';

const BBGiftsBanner = ({
  heading,
  subheading,
  image,
  link,
  variant = 'flowers',
  behaviorSettings = null,
  tag = 'CONCERN GIFTS',
  uuid = null,
}) => {
  let MainIcon = BalloonsIcon;
  let SubIcon = SubBalloonsIcon;
  let buttonType = 'cobalt';

  if (variant === 'flowers') {
    MainIcon = FlowerIcon;
    SubIcon = SubFlowersIcon;
    buttonType = 'secondary';
  }

  if (variant === 'hearts') {
    MainIcon = HeartsIcon;
    SubIcon = SubHeartsIcon;
    buttonType = 'tangerine';
  }

  return (
    <div
      className={`bb bb-gifts-banner ${styles['bb-gifts-banner']} ${
        styles[`bb-gifts-banner__${variant}`]
      } ${generateClassNameByBehaviorSettings(behaviorSettings)}`}
      id={uuid}
    >
      <div className="container">
        <div className={styles['bb-gifts-banner__wrapper']}>
          <div className={styles['bb-gifts-banner__image']}>
            <DefaultPicture {...image} />
          </div>
          <div className={styles['bb-gifts-banner__content']}>
            <MainIcon className={styles['bb-gifts-banner__main_icon']} />
            <SubIcon className={styles['bb-gifts-banner__sub_icon']} />

            <div className={styles['bb-gifts-banner__tag']}>{tag}</div>
            <h2 className={styles['bb-gifts-banner__heading']}>{heading}</h2>
            <div className={styles['bb-gifts-banner__subheading']}>{subheading}</div>
            <Link href={link.nextLink.href}>
              <Button withArrow type={buttonType}>
                {link.label}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

BBGiftsBanner.propTypes = {
  uuid: PropTypes.string,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  image: PropTypes.shape(defaultPicturePropTypes).isRequired,
  link: PropTypes.shape({
    label: PropTypes.string,
    nextLink: PropTypes.shape(linkPropTypes),
  }).isRequired,
  tag: PropTypes.string,
  variant: PropTypes.oneOf(['balloons', 'hearts', 'flowers']),
  behaviorSettings: behaviorSettingsProps,
};

export default BBGiftsBanner;
